<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Novo Agendamento</div>
    <div class="text-500 mb-5">Faça o agendamento do seu paciente.</div>
    <div class="surface-card p-4 shadow-2 border-round p-fluid mb-3">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="titulo" :class="{'p-error':v$.schedule.title.$error}">Titulo *</label>
          <InputText id="titulo"
                     v-model="schedule.title"
                     @blur="v$.schedule.title.$touch"
                     :class="{'p-invalid': v$.schedule.title.$error}"
          />
          <small class="p-error" v-for="error of v$.schedule.title.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field col-12 md:col-12">
          <label for="name" :class="{'p-error': v$.schedule.contact.$error}">Cliente *</label>
          <Dropdown id="name" v-model="schedule.contact"
                    :options="contacts"
                    @blur="v$.schedule.contact.$touch"
                    option-label="name"
                    required="true"
                    :class="{'p-invalid': v$.schedule.contact.$error}"
                    placeholder="Selecione um Paciente"
                    :filter="true"
                    filterPlaceholder="Pesquisar Paciente"/>
          <small class="p-error" v-for="error of v$.schedule.contact.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field col-12 md:col-12">
          <label for="procedimentos" :class="{'p-error': v$.schedule.procedures.$error}">Procedimentos *</label>
          <MultiSelect id="procedimentos" v-model="schedule.procedures"
                       :options="procedures"
                       option-label="name"
                       @blur="v$.schedule.procedures.$touch"
                       placeholder="Selecione os Procedimentos"
                       display="chip"
                       :class="{'p-invalid': v$.schedule.procedures.$error}" />
          <small class="p-error" v-for="error of v$.schedule.procedures.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field col-5 md:col-4">
          <label for="data" :class="{'p-error': v$.schedule.startDate.$error}">Data Início *</label>
          <span class="p-input-icon-right">
              <i class="pi pi-calendar" />
              <InputMask id="data" v-model="schedule.startDate"
                         show-icon
                         mask="99/99/9999 99:99"
                         :class="{'p-invalid': v$.schedule.startDate.$error}"
                         @blur="v$.schedule.startDate.$touch"
              />
            </span>
          <small class="p-error" v-for="error of v$.schedule.startDate.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field col-5 md:col-4">
          <label for="fim" :class="{'p-error': v$.schedule.endDate.$error}">Data Final *</label>
          <span class="p-input-icon-right">
              <i class="pi pi-calendar" />
              <InputMask id="fim"
                         v-model="schedule.endDate"
                         show-icon
                         mask="99/99/9999 99:99"
                         :class="{'p-invalid': v$.schedule.endDate.$error}"
                         @blur="v$.schedule.endDate.$touch"
              />
            </span>
          <small class="p-error" v-for="error of v$.schedule.endDate.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field col-2 md:col-4">
          <label for="status" :class="{'p-error': v$.schedule.schedulerType.$error}">Status do Atendimento *</label>
          <Dropdown id="status" :options="status"
                    v-model="schedule.schedulerType"
                    option-label="name" option-value="code"
                    placeholder="Selecione o status"
                    :class="{'p-invalid': v$.schedule.schedulerType.$error}"
                    @blur="v$.schedule.schedulerType.$touch"
          />
          <small class="p-error" v-for="error of v$.schedule.schedulerType.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field col-12 md:col-12" v-show="!isEspecialista">
          <label for="name" >Profissional *</label>
          <Dropdown id="name" v-model="schedule.user"
                    :options="users"
                    option-label="name"
                    placeholder="Selecione um Profissional"
                    :filter="true"
                    filterPlaceholder="Pesquisar Profissional"/>
        </div>
      </div>
    </div>

    <Toolbar class="mb-2">
      <template v-slot:end>
        <div class="my-2">
          <Button label="Cancelar" icon="pi" class="p-button-outlined mr-2" @click="$router.back()"/>
          <Button label="Salvar" icon="pi pi-save" class="p-button-success" @click="save"/>
        </div>
      </template>
    </Toolbar>
  </div>
</template>

<script>
import moment from "moment";
import ScheduleService from "@/service/ScheduleService";
import ContactService from "@/service/ContactService";
import ProcedureService from "@/service/ProcedureService";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import UserService from "@/service/UserService";
import _ from "lodash";


export default {
  name: "agendar",
  setup: () => ({ v$: useVuelidate() }),
  created() {
    moment.locale('pt-BR');
    this.scheduleService = new ScheduleService();
    this.contactService = new ContactService();
    this.procedureService = new ProcedureService();
    this.userService = new UserService();
  },
  async mounted() {
    await this.loadPage();
  },
  data() {
    return {
      schedule: {
        contact: {},
        procedures: [],
        user: {}
      },
      status: [
        {name: 'CONSULTA', code: 'CONSULTA'},
        {name: 'CONCLUÍDO', code: 'CONCLUIDO'},
        {name: 'CANCELADO', code: 'CANCELADO'},
        {name: 'RETORNO', code: 'RETORNO'}
      ],

      scheduleService: null,
      contactService: null,
      userService: null,
      contacts: null,
      users: [],
      selectedContact: null,
      procedureService: null,
      procedures: [],
      idSchedule: null,
      loader: null,
      isEspecialista: false
    }
  },
  validations() {
    return {
      schedule: {
        title: {
          required: helpers.withMessage('Campo Título é obrigatório.', required)
        },
        contact: {
          required: helpers.withMessage("Campo Contato é obrigatório.", required)
        },
        procedures: {
          required: helpers.withMessage("Campo Procedimento é obrigatório.", required)
        },
        startDate: {
          required: helpers.withMessage("Campo Data Inícial é obrigatório.", required)
        },
        endDate: {
          required: helpers.withMessage("Campo Data Final é obrigatório.", required)
        },
        schedulerType: {
          required: helpers.withMessage("Campo Status do Atendimento é obrigatório.", required)
        }
      }
    }
  },
  methods: {

    async loadPage() {
      this.loader = this.$loading.show();
      this.idSchedule = this.$route.query.id;
      this.verifyRole();

      await Promise.all([
        this.findAllContact(),
        this.findAllProcedures()
      ]);

      if(!this.isEspecialista) {
        await this.findAllUser();
      }

      if(this.idSchedule) {
        await this.findById();
      }

      this.loader.hide();
    },

    verifyRole() {
      const roles = JSON.parse(localStorage.getItem("user")).roles;

      const rolesString = [];
      roles.forEach((role) => {
        rolesString.push(role.name);
      });

      if(_.includes(rolesString, 'ROLE_ESPECIALISTA') && !_.includes(rolesString, 'ROLE_ADMIN')) {
        this.isEspecialista = true;
      }
    },

    async findById() {
      const response = await this.scheduleService.findById(this.idSchedule);
      if (response.status === 200) {
        this.schedule = response.data;
      }
    },

    async findAllContact() {
      const response = await this.contactService.findAll();
      if(response.status === 200) {
        this.contacts = response.data.content;
      }
    },
    async findAllUser() {
      const response = await this.userService.findByClinicalProfessional();
      if(response.status === 200) {
        this.users = response.data;
      }
    },
    async findAllProcedures() {
      const response = await this.procedureService.findAll();
      if(response.status === 200) {
        this.procedures = response.data.content;
      }
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    async save() {
      const isFormValid = await this.v$.$validate();
      if(!isFormValid) {
        return;
      }

      const dtInit = moment(this.schedule.startDate, "DD/MM/YYYY HH:mm");
      const dtEnd = moment(this.schedule.endDate, "DD/MM/YYYY HH:mm");

      if(dtInit.isAfter(dtEnd)) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Alerta',
          detail: 'Data final não pode ser anterior a data inicíal.',
          life: 3000
        });
        return;
      }

      this.loader = this.$loading.show();

      try {
        this.schedule.startDate = moment(this.schedule.startDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm");
        this.schedule.endDate = moment(this.schedule.endDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm");

        if(this.isEspecialista) {
          this.schedule.user.id = JSON.parse(localStorage.getItem("user")).id;
        }

        const response = await this.scheduleService.save(this.schedule);
        if(response.status === 200) {
          this.schedule = {};
          this.v$.$reset();
          this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Agendamento cadastrado com sucesso.', life: 3000});
          await this.$router.push('agendar');
        } else if (response.status === 210){
          this.$toast.add({severity:'warn', summary: 'Alerta', detail: 'Já existe um agendamento para esta data e profissional.', life: 3000});
        }
        this.loader.hide();
      } catch(e) {
        this.loader.hide();
        this.$toast.add({
          severity: 'error',
          summary: 'ERRO',
          detail: 'Ocorreu um erro interno no servidor, tente novamente mais tarde.',
          life: 3000
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
